import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteScheduledMeal,
  setLimit,
  setPage,
  updateScheduleDate
} from "../../features/mod/modSlice";
import PickerWithButtonField from "../Datepicker/Datepicker";
import { selectScheduledDates } from "../../features/mod/modSelectors";
import CancelIcon from "@mui/icons-material/Close";
import { VisibilityOutlined } from "@mui/icons-material";
import PreviewModal from "../Modals/PreviewModal";

// Custom CSS styles
const styles = {
  tableContainer: {
    borderRadius: "10px 10px 0 0",
    "&::-webkit-scrollbar": {
      width: "4px"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#374151",
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1"
    }
  },
  headerCell: {
    minWidth: 120,
    backgroundColor: "#374151",
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: 700,
    textAlign: "center" // Center the header text
  },
  tableRow: {
    fontSize: "12px"
  },
  imageCell: {
    width: 80,
    height: 60,
    borderRadius: "8px",
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  createdByCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "18px"
  },
  ellipsisCell: {
    maxWidth: 150, // Set your desired max width
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
};

const columns = (currentTab) => [
  {
    id: "date",
    label:
      currentTab === "Pending"
        ? "Request date"
        : currentTab === "Rejected"
        ? "Rejected date"
        : "Date",
    minWidth: 100
  },
  {
    id: "image",
    label: "Image",
    minWidth: 80,
    align: "center"
  },
  { id: "createdBy", label: "Created By", minWidth: 120, align: "left" },
  { id: "title", label: "Title", minWidth: 150, align: "left" },
  { id: "recipe", label: "Recipe", minWidth: 120, align: "left" },
  { id: "description", label: "Description", minWidth: 200, align: "left" },
  { id: "protein", label: "800g / Protein", minWidth: 100, align: "center" },
  ...(currentTab !== "Rejected"
    ? [
        {
          id: "actions",
          label: "",
          minWidth: 130,
          align: "center"
        }
      ]
    : [])
];

const calculateVegiesTotals = (meal) => {
  const { fruitsVeggies } = meal;

  // Calculate total for fruitsVeggies
  const totalFruitsVeggies = fruitsVeggies.reduce(
    (acc, item) => acc + item.quantity,
    0
  );

  // Calculate total for otherIngredients if present
  // const totalOtherIngredients =
  //   otherIngredients?.reduce((acc, item) => acc + item.quantity, 0) || 0;

  return totalFruitsVeggies;
};

const formatIngredients = (meal) => {
  const { fruitsVeggies, otherIngredients } = meal;

  // Format fruitsVeggies
  const formattedFruitsVeggies = fruitsVeggies
    .map((item) => `${item.quantity} ${item.unit} ${item.name}`)
    .join(", ");

  // Format otherIngredients if present
  const formattedOtherIngredients = otherIngredients
    ?.map((item) => `${item.quantity} ${item.unit} ${item.name}`)
    .join(", ");

  // Combine both formatted strings
  return formattedOtherIngredients
    ? `${formattedFruitsVeggies}, ${formattedOtherIngredients}`
    : formattedFruitsVeggies;
};

const StickyHeadTable = ({
  totalMods,
  mod,
  setIsAddingMeal,
  setScheduleDate,
  date,
  setIsRefresh,
  currentTab,
  setDate,
  setEditData
}) => {
  const [page, setNewPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openPreview, setOpenPreview] = React.useState(null);
  const dispatch = useDispatch();
  const disabledDates = useSelector(selectScheduledDates);

  const handleChangePage = (event, newPage) => {
    setNewPage(newPage);
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value;
    setRowsPerPage(newRowsPerPage);
    setNewPage(0);
    dispatch(setLimit(newRowsPerPage));
    dispatch(setPage(0));
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns(currentTab).map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={styles.headerCell}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {mod && mod?.length >= 0 ? (
              mod?.map((row, index) =>
                row.meals[0] ? (
                  // Check if meals[0] exists
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                    <TableCell style={styles.tableRow} align="center">
                      {currentTab === "Pending"
                        ? new Date(row?.meals[0]?.createdAt).toLocaleDateString(
                            "en-US"
                          )
                        : currentTab === "Rejected"
                        ? new Date(row?.meals[0]?.updatedAt).toLocaleDateString(
                            "en-US"
                          )
                        : row?.date || row?.meals[0]?.scheduledDate
                        ? new Date(
                            row?.date || row?.meals[0]?.scheduledDate
                          ).toLocaleDateString("en-US")
                        : ""}
                    </TableCell>
                    <TableCell
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        ...styles.tableRow
                      }}
                      align="center"
                    >
                      <Box sx={styles.imageCell}>
                        <Box
                          component="img"
                          borderRadius="8px"
                          border="1px solid #D1D5DB"
                          height={53}
                          width={75}
                          src={`${
                            process.env.REACT_APP_BACKEND_URL +
                            process.env.REACT_APP_BACKEND_MEAL_IMAGE_LOCATION
                          }/${row.meals[0]?.mealImage}`}
                          alt="avatar"
                        />
                      </Box>
                    </TableCell>

                    <TableCell style={styles.tableRow} align="center">
                      <Box
                        sx={styles.createdByCell}
                        display="flex"
                        alignItems="center"
                      >
                        <Avatar
                          alt={
                            row.meals[0]?.userData[0]?.fullName ||
                            row.meals[0]?.adminUserData[0]?.name
                          }
                          src={`${
                            process.env.REACT_APP_BACKEND_URL +
                            process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
                          }/${row.meals[0]?.userData[0]?.profilePic}`}
                          sx={{ width: 40, height: 40 }}
                        />
                        <Typography variant="body2">
                          {row.meals[0]?.userData[0]?.fullName ||
                            row.meals[0]?.adminUserData[0]?.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell style={styles.tableRow} align="center">
                      {row.meals[0]?.title}
                    </TableCell>
                    <TableCell
                      style={{ ...styles.tableRow, ...styles.ellipsisCell }}
                      align="center"
                    >
                      <Tooltip
                        title={
                          row.meals[0] ? formatIngredients(row.meals[0]) : ""
                        }
                      >
                        <span>
                          {row.meals[0] ? formatIngredients(row.meals[0]) : ""}
                        </span>
                      </Tooltip>
                    </TableCell>
                    <TableCell
                      style={{ ...styles.tableRow, ...styles.ellipsisCell }}
                      align="center"
                    >
                      <Tooltip title={row.meals[0]?.generalText}>
                        <span>{row.meals[0]?.generalText}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell style={styles.tableRow} align="center">
                      {row.meals[0] ? calculateVegiesTotals(row.meals[0]) : ""}/
                      {row.meals[0]?.protein?.reduce(
                        (acc, item) => acc + item.quantity,
                        0
                      )}
                      g
                    </TableCell>
                    {currentTab !== "Rejected" && (
                      <TableCell align="center">
                        <Box display="flex" gap="20px">
                          {currentTab === "Pending" ? (
                            <>
                              <PickerWithButtonField
                                date={date}
                                setDate={setDate}
                                isDateIcon={false}
                                disabledDates={disabledDates}
                                handleUpdate={async (e) => {
                                  const mainData = new FormData();
                                  const data = {
                                    scheduledDate: e.format("YYYY-MM-DD"),
                                    status: "approved"
                                  };

                                  const additionalJsonString =
                                    JSON.stringify(data);

                                  const additionalBase64EncodedData =
                                    btoa(additionalJsonString);

                                  mainData.append(
                                    "data",
                                    additionalBase64EncodedData
                                  );

                                  await dispatch(
                                    updateScheduleDate({
                                      mealData: mainData,
                                      mealId: row.meals[0]._id
                                    })
                                  );
                                  dispatch(setPage(0));
                                  dispatch(setLimit(10));
                                  setDate(null);
                                }}
                              />
                              <IconButton
                                sx={{
                                  backgroundColor: "#FF4000", // Red background
                                  color: "#fff", // White text
                                  "&:hover": {
                                    backgroundColor: "#d32f2f" // Darker red on hover
                                  },
                                  borderRadius: "50%" // Ensure the button has rounded corners
                                }}
                                onClick={async () => {
                                  setIsRefresh(false);
                                  const mainData = new FormData();
                                  const data = {
                                    scheduledDate: null,
                                    status: "rejected"
                                  };

                                  const additionalJsonString =
                                    JSON.stringify(data);

                                  const additionalBase64EncodedData =
                                    btoa(additionalJsonString);

                                  mainData.append(
                                    "data",
                                    additionalBase64EncodedData
                                  );

                                  await dispatch(
                                    updateScheduleDate({
                                      mealData: mainData,
                                      mealId: row.meals[0]._id
                                    })
                                  );
                                  setIsRefresh(true);
                                }}
                              >
                                <CancelIcon fontSize="small" />
                              </IconButton>
                            </>
                          ) : (
                            <>
                              {currentTab !== "Past" && (
                                <>
                                  <IconButton
                                    sx={{
                                      height: "32px",
                                      width: "32px",
                                      backgroundColor: "#F3F4F6",
                                      borderRadius: "4px"
                                    }}
                                    onClick={() => {
                                      setOpenPreview(row.meals[0]);
                                    }}
                                    size="small"
                                    aria-label="edit"
                                  >
                                    <VisibilityOutlined fontSize="small" />
                                  </IconButton>
                                  <PreviewModal
                                    onClose={() => setOpenPreview(null)}
                                    currentMeal={openPreview}
                                  />
                                  <IconButton
                                    sx={{
                                      height: "32px",
                                      width: "32px",
                                      backgroundColor: "#F3F4F6",
                                      borderRadius: "4px"
                                    }}
                                    onClick={() => {
                                      setIsAddingMeal(true);
                                      setScheduleDate(
                                        new Date(row.date).toLocaleDateString(
                                          "en-US"
                                        )
                                      );
                                      setEditData(row.meals[0]);
                                    }}
                                    size="small"
                                    aria-label="edit"
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                  <IconButton
                                    sx={{
                                      height: "32px",
                                      width: "32px",
                                      backgroundColor: "#F3F4F6",
                                      borderRadius: "4px"
                                    }}
                                    size="small"
                                    aria-label="delete"
                                    onClick={async () => {
                                      setIsRefresh(false);
                                      await dispatch(
                                        deleteScheduledMeal({
                                          mealId: row.meals[0]._id
                                        })
                                      );
                                      setIsRefresh(true);
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </>
                              )}

                              <PickerWithButtonField
                                date={date}
                                setDate={setDate}
                                disabledDates={disabledDates}
                                handleUpdate={async (e) => {
                                  const mainData = new FormData();
                                  const data = {
                                    scheduledDate: e.format("YYYY-MM-DD"),
                                    ...(currentTab === "Past" && {
                                      isDeleted: false
                                    })
                                  };

                                  const additionalJsonString =
                                    JSON.stringify(data);

                                  const additionalBase64EncodedData =
                                    btoa(additionalJsonString);

                                  mainData.append(
                                    "data",
                                    additionalBase64EncodedData
                                  );

                                  await dispatch(
                                    updateScheduleDate({
                                      mealData: mainData,
                                      mealId: row.meals[0]._id
                                    })
                                  );
                                  dispatch(setPage(0));
                                  dispatch(setLimit(10));
                                  setDate(null);
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </TableCell>
                    )}
                  </TableRow>
                ) : (
                  <TableRow
                    key={index}
                    sx={{ backgroundColor: "#FCDADA", height: "70px" }}
                  >
                    <TableCell
                      colSpan={8}
                      align="center"
                      style={{
                        color: "black"
                      }}
                    >
                      No Meal has been scheduled on{" "}
                      <b>{new Date(row.date).toLocaleDateString("en-US")}</b>
                      <Button
                        sx={{
                          fontSize: "14px",
                          marginLeft: "20px",
                          padding: "8px 16px",
                          background: "#38B65B",
                          border: "1px solid #38B65B",
                          color: "#fff",
                          fontWeight: 700,
                          textTransform: "none"
                        }}
                        onClick={() => {
                          setEditData(null);
                          setIsAddingMeal(true);
                          setScheduleDate(
                            new Date(row.date).toLocaleDateString("en-US")
                          );
                        }}
                        variant="outlined"
                      >
                        Add a meal
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              )
            ) : (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalMods}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default StickyHeadTable;
