import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import AllUsers from "../Tables/UserTable"; // Updated import
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllUsers,
  selectCurrentPage,
  selectLimit,
  selectTotalUsers,
  selectUsersError,
  selectUsersLoading
} from "../../features/users/userSelectors";
import {
  fetchAllUsers,
  setLimit,
  setPage
} from "../../features/users/userSlice";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      textTransform={"none"}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, pb: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
};

const UserTab = ({ isAdmin, openModal, searchValue }) => {
  const [value, setValue] = useState(0);
  const [currentTab, setCurrentTab] = useState("All");
  const dispatch = useDispatch();

  const users = useSelector(selectAllUsers); // Now fetched from Redux
  const totalUsers = useSelector(selectTotalUsers);
  const page = useSelector(selectCurrentPage);
  const limit = useSelector(selectLimit);
  const loading = useSelector(selectUsersLoading);
  const error = useSelector(selectUsersError);

  useEffect(() => {
    if (searchValue?.length > 3) {
      dispatch(setPage(0));
      dispatch(setLimit(10));
    }
    if (!searchValue || searchValue?.length > 3) {
      dispatch(
        fetchAllUsers({
          ...(currentTab !== "All" && { currentTab }),
          page,
          limit,
          searchValue
        })
      );
      // }
    }
  }, [dispatch, page, limit, currentTab, openModal, searchValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setPage(0));
    dispatch(setLimit(10));
    setCurrentTab(
      newValue === 0
        ? "All"
        : newValue === 1
        ? "Gym"
        : newValue === 2
        ? "Coach"
        : "Company"
    );
  };

  return (
    <Box mt={4}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        sx={{
          "& .MuiTab-root": { minWidth: "130px" },
          "& .MuiTabs-indicator": { background: "#38B65B" }
        }}
      >
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#38B65B" }
          }}
          label="All"
        />
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#38B65B" }
          }}
          label="Gym"
        />
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#38B65B" }
          }}
          label="Coach"
        />
        <Tab
          sx={{
            textTransform: "none",
            "&.Mui-selected": { color: "#38B65B" }
          }}
          label="Company"
        />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AllUsers
          totalUsers={totalUsers}
          users={users}
          loading={loading}
          error={error}
          currentTab={currentTab}
        />{" "}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AllUsers
          totalUsers={totalUsers}
          users={users}
          loading={loading}
          error={error}
          currentTab={currentTab}
        />{" "}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <AllUsers
          totalUsers={totalUsers}
          users={users}
          loading={loading}
          error={error}
          currentTab={currentTab}
        />{" "}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AllUsers
          totalUsers={totalUsers}
          users={users}
          loading={loading}
          error={error}
          currentTab={currentTab}
        />{" "}
      </TabPanel>
    </Box>
  );
};

export default UserTab;
