import { Box, Button, InputAdornment, TextField } from "@mui/material";
import React from "react";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as FilterIcon } from "../../assets/filter.svg";
import ModTab from "../SubTabs/ModTab";
import AddMod from "../Modals/AddMod";
import { setLimit, setPage } from "../../features/mod/modSlice";
import { useDispatch } from "react-redux";

const Mod = ({ setIsAddingMeal, isAddingMeal }) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [scheduleDate, setScheduleDate] = React.useState("");
  const [editData, setEditData] = React.useState(null);
  const dispatch = useDispatch();
  return !isAddingMeal ? (
    <Box>
      <Box display="flex" maxHeight="48px" justifyContent="space-between">
        <Box display="flex" gap={2}>
          <TextField
            variant="outlined" // You can change this to "filled" or "standard" as needed
            placeholder="Search..."
            size="small" // Adjust size if needed
            fullWidth // Makes the search bar take full width
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ height: "17px" }} />
                  </InputAdornment>
                )
              }
            }}
            sx={{
              "& .MuiInputBase-root": {
                padding: "14px 11px",
                height: "100%",
                //   width: "387px",
                borderRadius: 2
              },
              width: "387px",
              fontSize: "14px",
              background: "#FFFFFF", // Optional: set background color
              borderRadius: 2 // Optional: round corners
            }}
          />
          <Button
            sx={{
              fontSize: "14px",
              padding: "16px 24px",
              border: "1px solid #6B7280",
              color: "#6B7280",
              fontWeight: 700,
              textTransform: "none"
            }}
            variant="outlined"
            disableRipple
            startIcon={<FilterIcon />}
          >
            Filter
          </Button>
        </Box>
        <Box display="flex" gap={2}>
          <Button
            sx={{
              fontSize: "14px",
              padding: "16px 24px",
              background: "#38B65B",
              border: "1px solid #38B65B",
              color: "#fff",
              fontWeight: 700,
              textTransform: "none"
            }}
            onClick={() => {
              setIsAddingMeal(true);
              setScheduleDate("");
              setEditData(null);
              dispatch(setPage(0));
              dispatch(setLimit(10));
            }}
            variant="outlined"
          >
            Add a meal
          </Button>
        </Box>
      </Box>

      <ModTab
        setScheduleDate={setScheduleDate}
        searchValue={searchValue}
        isAddingMeal={isAddingMeal}
        setIsAddingMeal={setIsAddingMeal}
        setEditData={setEditData}
      />
      {/*  <AddUserModal open={openModal} onClose={() => setOpenModal(false)} /> */}
    </Box>
  ) : (
    <AddMod
      scheduleDate={scheduleDate}
      setIsAddingMeal={setIsAddingMeal}
      editData={editData}
    />
  );
};

export default Mod;
