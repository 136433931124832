import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import { ReactComponent as ChartIcon } from "../../assets/chart.svg";
import { ReactComponent as DollarIcon } from "../../assets/dollar.svg";
import { useDispatch } from "react-redux";
import { setLimit, setPage } from "../../features/users/userSlice";
import { Typography } from "@mui/material";
import getInitials from "../../utils/getInitials";

// Custom CSS styles
const styles = {
  tableContainer: {
    borderRadius: "10px 10px 0 0",
    "&::-webkit-scrollbar": {
      width: "4px"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#374151",
      borderRadius: "4px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1"
    }
  },
  headerCell: {
    minWidth: 120,
    backgroundColor: "#374151",
    color: "#ffffff",
    fontSize: "14px",
    fontWeight: 700,
    textAlign: "center" // Center the header text
  },
  tableRow: {
    fontSize: "12px"
  }
};

const columns = (currentTab, isAdmin) => [
  { id: "name", label: "Name", minWidth: 120 },
  { id: "email", label: "Email", minWidth: 120 },
  isAdmin ? { id: "role", label: "Role", minWidth: 60 } : null,
  isAdmin
    ? {
        id: "coach",
        label: currentTab !== "All" ? currentTab : "Coach / Gym / Company",
        minWidth: 120,
        align: "center"
      }
    : null,
  { id: "joined", label: "Joined", minWidth: 60, align: "center" },
  { id: "lastLogin", label: "Last login", minWidth: 70, align: "center" },
  { id: "badges", label: "Badges", minWidth: 110, align: "center" },
  { id: "actions", minWidth: 130, align: "center" }
];

const StickyHeadTable = ({
  isAdmin = true,
  totalUsers,
  users,
  loading,
  error,
  currentTab
}) => {
  const [page, setNewPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    setNewPage(newPage);
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setNewPage(0);
    dispatch(setPage(0));
    dispatch(setLimit(+event.target.value));
  };

  const getRoleChipStyle = (role) => {
    switch (role) {
      case "Gym":
        return {
          style: {
            backgroundColor: "#D7F0DE",
            fontSize: "12px",
            color: "#38B65B"
          },
          label: "Gym"
        };
      case "user":
        return {
          style: {
            backgroundColor: "#FCECD3",
            fontSize: "12px",
            color: "#F1B44C"
          },
          label: "User"
        };
      case "admin":
        return {
          style: {
            backgroundColor: "#D7F0DE",
            fontSize: "12px",
            color: "#38B65B"
          },
          label: "Admin"
        };
      case "Coach":
        return {
          style: {
            backgroundColor: "#FDD9CD",
            fontSize: "12px",
            color: "#F6541C"
          },
          label: "Coach"
        };
      case "Company":
        return {
          style: {
            backgroundColor: "#D4E9FC",
            fontSize: "12px",
            color: "#368AD0"
          },
          label: "Company"
        };
      default:
        return { style: {} };
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={styles.tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns(currentTab, isAdmin)
                ?.filter(Boolean)
                ?.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={styles.headerCell}
                  >
                    {column.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((row) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={row.email}>
                <TableCell style={styles.tableRow} align="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="left"
                    gap={2}
                  >
                    {row?.profilePic ? (
                      <Box
                        component="img"
                        borderRadius="50%"
                        height={40}
                        width={40}
                        src={`${
                          process.env.REACT_APP_BACKEND_URL +
                          process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
                        }/${row?.profilePic}`}
                        alt="avatar"
                      />
                    ) : (
                      <Avatar sx={{ width: 40, height: 40, mr: 1 }}>
                        {row.displayName
                          ? getInitials(row.displayName)
                          : getInitials(row.fullName)}
                      </Avatar>
                    )}
                    <Typography fontSize="small">
                      {row.displayName ? row.displayName : row.fullName}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell style={styles.tableRow} align="center">
                  {row.email}
                </TableCell>
                {isAdmin ? (
                  <>
                    <TableCell style={styles.tableRow} align="center">
                      <Chip
                        label={
                          getRoleChipStyle(
                            row.isAdmin === 1
                              ? currentTab === "All"
                                ? row.userType
                                : "admin"
                              : "user"
                          ).label
                        }
                        sx={
                          getRoleChipStyle(
                            row.isAdmin === 1
                              ? currentTab === "All"
                                ? row.userType
                                : "admin"
                              : "user"
                          ).style
                        }
                      />
                    </TableCell>
                    <TableCell style={styles.tableRow} align="center">
                      {row.referenceId ? (
                        <Box
                          display="flex"
                          justifyContent="left"
                          alignItems="center"
                          gap={2}
                        >
                          {row?.userTypeDetails?.profilePic ? (
                            <Box
                              component="img"
                              borderRadius="50%"
                              height={40}
                              width={40}
                              src={`${
                                process.env.REACT_APP_BACKEND_URL +
                                process.env
                                  .REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
                              }/${row?.userTypeDetails?.profilePic}`}
                              alt="avatar"
                            />
                          ) : (
                            <Avatar sx={{ width: 40, height: 40, mr: 1 }}>
                              {getInitials(
                                row.userTypeDetails?.[
                                  `${row.userType?.toLowerCase()}Name`
                                ]
                              )}
                            </Avatar>
                          )}
                          <Typography fontSize="small">
                            {
                              row.userTypeDetails?.[
                                `${row.userType?.toLowerCase()}Name`
                              ]
                            }
                          </Typography>
                        </Box>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                  </>
                ) : null}

                <TableCell style={styles.tableRow} align="center">
                  {new Date(row.createdAt).toLocaleDateString("en-US")}
                </TableCell>
                <TableCell style={styles.tableRow} align="center">
                  {row.lastLogin
                    ? new Date(row.lastLogin).toLocaleDateString("en-US")
                    : "N/A"}
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" alignItems="center">
                    {row.badges?.slice(0, 2).map((badge, index) => (
                      <Avatar
                        key={index}
                        sx={{ width: 30, height: 30, mr: 0.5 }}
                      >
                        {badge?.charAt(0)}
                      </Avatar>
                    ))}
                    {row.badges?.length > 2 && (
                      <Chip
                        style={{ fontSize: "12px" }}
                        label={`+${row.badges?.length - 2} More`}
                      />
                    )}
                  </Box>
                </TableCell>
                <TableCell align="center">
                  <Box display="flex" gap="20px">
                    <IconButton
                      sx={{
                        height: "32px",
                        width: "32px",
                        backgroundColor: "#F3F4F6",
                        borderRadius: "4px"
                      }}
                      size="small"
                      aria-label="edit"
                    >
                      <ChartIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      sx={{
                        height: "32px",
                        width: "32px",
                        backgroundColor: "#F3F4F6",
                        borderRadius: "4px"
                      }}
                      size="small"
                      aria-label="view"
                    >
                      <DollarIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      sx={{
                        height: "32px",
                        width: "32px",
                        backgroundColor: "#F3F4F6",
                        borderRadius: "4px"
                      }}
                      size="small"
                      aria-label="info"
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      sx={{
                        height: "32px",
                        width: "32px",
                        backgroundColor: "#F3F4F6",
                        borderRadius: "4px"
                      }}
                      size="small"
                      aria-label="delete"
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalUsers}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default StickyHeadTable;
