import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectAuthLoading } from "../../features/auth/authSelectors";
import {
  TextField,
  Button,
  Typography,
  CircularProgress,
  Snackbar,
  Avatar,
  Box,
  styled,
  Alert,
  useMediaQuery,
  InputAdornment,
  IconButton
} from "@mui/material";
import { ReactComponent as CameraIcon } from "../../assets/camera.svg";
import { registerUser } from "../../features/auth/authSlice";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(4, 0)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-18px",
  color: "#14142B",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#1C1C1C"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#D1D5DB"
    },
    "&:hover fieldset": {
      borderColor: "#1C1C1C"
    },
    "&.Mui-focused fieldset": {
      color: "#1C1C1C",
      borderColor: "#D1D5DB"
    }
  }
}));

const Register = () => {
  const location = useLocation();
  const { role } = location.state || { role: null };
  const isCoach = role === "Coach";
  const isGym = role === "Gym";

  const [formData, setFormData] = useState({
    fullName: "",
    displayName: "",
    gymName: "",
    companyName: "",
    email: "",
    password: "",
    profilePic: null,
    bio: "",
    url: ""
  });

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    displayName: false,
    email: false,
    password: false,
    bio: false,
    url: false
  });
  const matches = useMediaQuery("(max-width:590px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };
  const dispatch = useDispatch();
  const loading = useSelector(selectAuthLoading);
  const navigate = useNavigate();

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const validateUrl = (url) =>
    /(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/.test(
      url
    );
  const validatePassword = (password) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}$/.test(
      password
    );

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });

    if (name === "email") {
      setFormErrors({ ...formErrors, email: !validateEmail(value) });
    } else if (name === "url") {
      setFormErrors({ ...formErrors, url: !validateUrl(value) });
    } else if (name === "password") {
      setFormErrors({ ...formErrors, password: !validatePassword(value) });
    } else if (name === "bio" && value.length && value.length > 50) {
      setFormErrors({ ...formErrors, bio: true });
    } else {
      setFormErrors({ ...formErrors, [name]: value.trim() === "" });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 30 * 1024 * 1024) {
      setSnackbarMessage("File size should be less than 30MB.");
      setOpenSnackbar(true);
      return;
    }
    if (file) {
      setFormData((prevState) => ({
        ...prevState,
        profilePic: file
      }));
    }
  };

  const handleRegister = async (e) => {
    const mainData = new FormData();
    mainData.append("fullName", formData.fullName);
    mainData.append("displayName", formData.displayName);
    mainData.append("email", formData.email);
    mainData.append("password", formData.password);
    mainData.append("bio", formData.bio);
    mainData.append("url", formData.url);
    mainData.append("userType", role);
    if (!isCoach) {
      mainData.append(
        `${role?.toLowerCase()}Name`,
        formData[`${role?.toLowerCase()}Name`]
      );
    }
    if (formData.profilePic) {
      mainData.append("profilePic", formData.profilePic);
    }
    e.preventDefault();
    if (Object.values(formErrors).some((error) => error)) {
      setSnackbarMessage("Please correct the form errors.");
      setOpenSnackbar(true);
      return;
    }
    const { payload } = await dispatch(
      registerUser({ mainData, role: role.toLowerCase() })
    );
    if (!payload.error) navigate("/dashboard");
    else {
      setSnackbarMessage(payload.error || "Registration failed.");
      setOpenSnackbar(true);
    }
  };

  return (
    <Box
      display={"flex"}
      width="100%"
      overflow="hidden"
      height="100vh"
      sx={{
        flexDirection: { xs: "column", md: "row" }
      }}
    >
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { xs: 0, md: "50%" },
          display: { xs: "none", md: "block" },
          objectFit: "cover"
        }}
      >
        <Box
          component="img"
          src="/images/base.png"
          alt="base"
          sx={{
            width: "100%",
            height: "100vh",
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        sx={{
          left: 0,
          top: 0,
          width: { md: "0" },
          objectFit: "cover",
          display: { xs: "flex", md: "none" },
          flexDirection: "column",
          gap: "20px",
          alignItems: "center",
          marginTop: "20px"
        }}
      >
        <Box
          component="img"
          src="/images/threepillars.png"
          alt="base"
          sx={{
            width: "100px",
            height: "100px",
            objectFit: "cover"
          }}
        />
      </Box>
      <Box
        flexGrow={1}
        paddingY={3}
        paddingLeft={10}
        sx={{
          paddingTop: { xs: 5, md: 8, lg: 10 },
          paddingRight: { xs: 5, md: 13, lg: 20 },
          paddingBottom: { xs: 5, md: 8, lg: 10 },
          paddingLeft: { xs: 5, md: 10 },
          height: { xs: "93%", md: "80%" },
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          scrollbarWidth: "thin",
          scrollbarColor: "rgba(255, 255, 255, 0.5)",
          "&::-webkit-scrollbar": {
            width: "4px"
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            borderRadius: "6px"
          }
        }}
      >
        <Typography
          sx={{
            ...(matches && { display: "flex", justifyContent: "center" })
          }}
          fontSize="30px"
          fontWeight="bold"
          gutterBottom
        >
          Create account for {role?.toLowerCase()}
        </Typography>
        <Snackbar
          open={openSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={1000}
          onClose={() => setOpenSnackbar(false)}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Box component={"form"} mt={2} onSubmit={handleRegister}>
          <input
            accept="image/*"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="profile-pic-upload"
          />
          <Box
            sx={{
              marginBottom: 6,
              ...(matches && { justifyContent: "center", alignItems: "center" })
            }}
            display="flex"
            flexDirection="column"
          >
            <Typography
              color="#14142B"
              fontWeight="bold"
              fontSize="14px"
              pointerEvents="none"
              htmlFor="avatar"
            >
              {role} Logo
            </Typography>
            {formData.profilePic ? (
              <Avatar
                alt="Profile Pic"
                src={URL.createObjectURL(formData.profilePic)}
                sx={{
                  width: 76,
                  height: 76,
                  marginTop: 2,
                  border: "1px solid #38B65B"
                }}
              />
            ) : (
              <label htmlFor="profile-pic-upload">
                <Button
                  variant="contained"
                  component="span"
                  color="default"
                  style={{
                    borderRadius: "48%",
                    backgroundColor: "#ccc",
                    width: 76,
                    height: 76,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 16,
                    cursor: "pointer"
                  }}
                >
                  <CameraIcon style={{ width: 24, height: 24 }} />
                </Button>
              </label>
            )}
          </Box>
          {isCoach ? (
            <StyledTextFieldContainer>
              <StyledInputLabel htmlFor="fullName">Name</StyledInputLabel>
              <StyledTextField
                fullWidth
                variant="outlined"
                name="fullName"
                placeholder="Enter name"
                value={formData.fullName}
                onChange={handleInputChange}
                required
                error={formErrors.fullName}
                helperText={formErrors.fullName && "Name is required"}
                margin="normal"
              />
            </StyledTextFieldContainer>
          ) : (
            <StyledTextFieldContainer>
              <StyledInputLabel htmlFor="fullName">Admin name</StyledInputLabel>
              <StyledTextField
                fullWidth
                variant="outlined"
                name="fullName"
                placeholder="Enter admin name"
                value={formData.fullName}
                onChange={handleInputChange}
                required
                error={formErrors.fullName}
                helperText={formErrors.fullName && "Admin name is required"}
                margin="normal"
              />
            </StyledTextFieldContainer>
          )}
          {isCoach && (
            <>
              <StyledTextFieldContainer>
                <StyledInputLabel htmlFor="displayName">
                  Username (This will be displayed in app)
                </StyledInputLabel>
                <StyledTextField
                  fullWidth
                  variant="outlined"
                  name="displayName"
                  placeholder="Enter username"
                  value={formData.displayName}
                  onChange={handleInputChange}
                  required
                  error={formErrors.displayName}
                  helperText={formErrors.displayName && "Username is required"}
                  margin="normal"
                />
              </StyledTextFieldContainer>
              <StyledTextFieldContainer>
                <StyledInputLabel htmlFor="bio">
                  Bio (50 character text)
                </StyledInputLabel>
                <StyledTextField
                  sx={{
                    "& .MuiInputBase-root": {
                      color: "#1C1C1C"
                    }
                  }}
                  fullWidth
                  variant="outlined"
                  name="bio"
                  placeholder="Enter bio"
                  value={formData.bio}
                  multiline
                  rows={4}
                  onChange={handleInputChange}
                  margin="normal"
                  error={formData.bio && formErrors.bio}
                  helperText={
                    formData.bio &&
                    formErrors.bio &&
                    "Bio should be less than 50 characters"
                  }
                />
                <Typography
                  variant="caption"
                  sx={{
                    color: formData.bio.length > 50 ? "red" : "#D1D5DB",
                    position: "absolute",
                    fontSize: "15px",
                    right: 10,
                    bottom: 10 // Adjust this value as necessary
                  }}
                >
                  {formData.bio.length}/50
                </Typography>
              </StyledTextFieldContainer>
            </>
          )}
          {!isCoach && (
            <StyledTextFieldContainer>
              <StyledInputLabel htmlFor={`${role?.toLowerCase()}Name`}>
                {role} Name
              </StyledInputLabel>
              <StyledTextField
                fullWidth
                variant="outlined"
                name={`${role?.toLowerCase()}Name`}
                htmlFor={`${role?.toLowerCase()}Name`}
                placeholder={`Enter ${role?.toLowerCase()} name`}
                value={formData[`${role?.toLowerCase()}Name`]}
                onChange={handleInputChange}
                required
                error={formErrors[`${role?.toLowerCase()}Name`]}
                helperText={
                  formErrors[`${role?.toLowerCase()}Name`] &&
                  `${role} name is required`
                }
                margin="normal"
              />
            </StyledTextFieldContainer>
          )}
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="email">
              {!isCoach ? "Admin email" : "Email"} address
            </StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              name="email"
              type="email"
              placeholder="Enter email address"
              value={formData.email}
              onChange={handleInputChange}
              required
              error={formErrors.email}
              helperText={formErrors.email && "Enter a valid email address"}
              margin="normal"
            />
          </StyledTextFieldContainer>
          <StyledTextFieldContainer>
            <StyledInputLabel htmlFor="password">Password</StyledInputLabel>
            <StyledTextField
              fullWidth
              variant="outlined"
              type={showPassword ? "text" : "password"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      onMouseUp={handleMouseUpPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              name="password"
              placeholder="Enter Password"
              value={formData.password}
              onChange={handleInputChange}
              required
              error={formErrors.password}
              helperText={
                formErrors.password &&
                "Password must be 8 characters long: Contains 1 upper, 1 lower case and 1 digit"
              }
              margin="normal"
            />
          </StyledTextFieldContainer>
          {isCoach && (
            <StyledTextFieldContainer>
              <StyledInputLabel htmlFor="url">
                URL (link to their website, social media, etc.)
              </StyledInputLabel>
              <StyledTextField
                fullWidth
                variant="outlined"
                name="url"
                placeholder="Enter URL"
                value={formData.url}
                onChange={handleInputChange}
                error={formErrors.url}
                helperText={formErrors.url && "Enter a valid URL"}
                margin="normal"
              />
            </StyledTextFieldContainer>
          )}
          <Box
            backgroundColor="#FCECD3"
            borderRadius="8px"
            border="1px solid #F1B44C"
            display="flex"
            padding="18px"
            alignItems="end"
            gap="3px"
            justifyContent="space-between"
          >
            <Typography fontWeight="bold">Plan starts</Typography>
            <Box display="flex" alignItems="end">
              <Typography fontWeight="bold">
                $ {isGym ? "250" : isCoach ? "20" : "100"}
              </Typography>
              <Typography fontSize="13px" color="#6B7280">
                {isGym
                  ? "/ 100 seat/yr"
                  : isCoach
                  ? "/ 25 seat/month"
                  : "/ 50 users/mo"}
              </Typography>
            </Box>
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            style={{
              marginTop: 25,
              textTransform: "none",
              backgroundColor: "#38B65B",
              color: "#fff",
              height: "42px",
              fontWeight: "bold"
            }}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : "Proceed to pay"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Register;
