import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
  Avatar,
  styled,
  Snackbar,
  Alert
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as CameraIcon } from "../../assets/camera.svg";
import { useDispatch, useSelector } from "react-redux";
import { addNewUser } from "../../features/auth/authSlice";

// Styled Components
const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  width: "100%",
  marginTop: theme.spacing(5)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-22px",
  color: "#14142B",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  input: {
    color: "#1C1C1C"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#D1D5DB"
    },
    "&:hover fieldset": {
      borderColor: "#1C1C1C"
    },
    "&.Mui-focused fieldset": {
      color: "#1C1C1C",
      borderColor: "#D1D5DB"
    }
  }
}));

const AddUserModal = ({ isAdmin, open, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    // role: "User",
    profilePic: null
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    password: false
  });
  const currentAdmin = useSelector((state) => state.adminauth.admin);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();
  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
  const validatePassword = (password) =>
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,}$/.test(
      password
    );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));

    if (name === "email") {
      setFormErrors({ ...formErrors, email: !validateEmail(value) });
    } else if (name === "password") {
      setFormErrors({ ...formErrors, password: !validatePassword(value) });
    } else {
      setFormErrors({ ...formErrors, [name]: value.trim() === "" });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 30 * 1024 * 1024) {
      setSnackbarMessage("File size should be less than 30MB.");
      setOpenSnackbar(true);
      return;
    }
    setFormData((prev) => ({
      ...prev,
      profilePic: file
    }));
  };

  const handleSubmit = async (e) => {
    if (Object.values(formErrors).some((error) => error)) {
      setSnackbarMessage("Please correct the form errors.");
      setOpenSnackbar(true);
      return;
    }
    const mainData = new FormData();
    mainData.append("fullName", formData.name);
    mainData.append("email", formData.email);
    mainData.append("password", formData.password);
    mainData.append("userType", currentAdmin?.userType || "Individual");
    if (currentAdmin?.referenceId)
      mainData.append("referenceId", currentAdmin?.referenceId || "");

    if (formData.profilePic) {
      mainData.append("profilePic", formData.profilePic);
    }
    e.preventDefault();
    const { payload } = await dispatch(addNewUser(mainData));
    if (!payload.error) {
      handleReset();
    } else {
      setSnackbarMessage(payload.error || "Adding new user failed.");
      setOpenSnackbar(true);
    }
  };

  const handleReset = () => {
    onClose();
    setFormData({
      name: "",
      email: "",
      password: "",
      // role: "User",
      profilePic: null
    });
    setFormErrors({
      name: false,
      email: false,
      password: false
    });
  };

  return (
    <Dialog open={open} onClose={handleReset} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Typography variant="h6" fontWeight="bold">
          Add User
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Box>
          {formData.profilePic ? (
            <Avatar
              sx={{
                width: 200,
                height: 200,
                bgcolor: "#f0f0f0",
                color: "#bdbdbd",
                mb: 2
              }}
              src={URL.createObjectURL(formData.profilePic)}
            />
          ) : (
            <Box
              onClick={() => document.getElementById("upload-input").click()}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: 200,
                height: 200,
                border: "2px dashed #D1D5DB",
                borderRadius: "50%",
                position: "relative",
                cursor: "pointer",
                bgcolor: "#F9FAFB"
              }}
            >
              <CameraIcon />
              <Typography fontWeight="bold" color="#4B5563" mt={1}>
                Tap to upload
              </Typography>
              <Typography fontSize="12px" color="#6B7280">
                (Supported file PNG, JPEG)
              </Typography>
              <input
                id="upload-input"
                type="file"
                accept=".png, .jpg, .jpeg"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </Box>
          )}
        </Box>
        <StyledTextFieldContainer>
          <StyledInputLabel htmlFor="name">Name</StyledInputLabel>
          <StyledTextField
            fullWidth
            name="name"
            placeholder="Enter name"
            value={formData.name}
            onChange={handleChange}
            required
            error={formErrors.name}
            helperText={formErrors.name && "Name is required"}
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer>
          <StyledInputLabel htmlFor="email">Email Address</StyledInputLabel>
          <StyledTextField
            fullWidth
            name="email"
            placeholder="Enter email address"
            value={formData.email}
            onChange={handleChange}
            required
            error={formErrors.email}
            helperText={formErrors.email && "Enter a valid email address"}
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer style={{ marginBottom: 8 }}>
          <StyledInputLabel htmlFor="password">Password</StyledInputLabel>
          <StyledTextField
            fullWidth
            name="password"
            type="password"
            placeholder="Enter password"
            value={formData.password}
            onChange={handleChange}
            required
            error={formErrors.password}
            helperText={
              formErrors.password &&
              "Password must be 8 characters long: Contains 1 upper, 1 lower case and 1 digit"
            }
          />
        </StyledTextFieldContainer>
        {/* <FormControl fullWidth margin="normal">
          <InputLabel>Role</InputLabel>
          <Select
            value={formData.role}
            name="role"
            onChange={handleChange}
            label="Role"
          >
            <MenuItem value="User">User</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Manager">Manager</MenuItem>
          </Select>
        </FormControl> */}
      </DialogContent>
      <DialogActions style={{ marginTop: 2, padding: 24 }}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          sx={{
            textTransform: "none",
            backgroundColor: "#38B65B",
            color: "#fff",
            ":disabled": {
              backgroundColor: "#D7F0DE",
              color: "#9CDBAD"
            }
          }}
          disabled={!formData.name || !formData.email || !formData.password}
        >
          Add
        </Button>
      </DialogActions>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default AddUserModal;
