import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  styled,
  TextField,
  Typography
} from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReactComponent as CameraIcon } from "../../assets/camera.svg";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { addNewMod, updateScheduleDate } from "../../features/mod/modSlice";
import { ReactComponent as Delete } from "../../assets/delete.svg";
import dayjs from "dayjs";
import { selectScheduledDates } from "../../features/mod/modSelectors";

const StyledTextFieldContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  margin: theme.spacing(1, 0, 3, 3)
}));

const StyledInputLabel = styled("label")(({ theme }) => ({
  position: "absolute",
  top: "-12px",
  color: "#333333",
  fontWeight: "bold",
  fontSize: "14px",
  pointerEvents: "none"
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& fieldset": {
      color: "#9CA3AF",
      borderColor: "#D1D5DB"
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D1D5DB"
    }
  }
}));

const AddMod = ({ setIsAddingMeal, scheduleDate, editData }) => {
  console.log("scheduleDate", scheduleDate);
  const [formData, setFormData] = useState({
    title: editData?.title ? editData.title : "",
    generalText: editData?.generalText ? editData.generalText : "",
    profilePic: null,
    fruitsVeggies: editData?.fruitsVeggies?.length
      ? editData?.fruitsVeggies
      : [{ name: "", quantity: "", unit: "gram" }],
    proteins: editData?.protein?.length
      ? editData?.protein
      : [{ name: "", quantity: "", unit: "gram" }],
    others: editData?.otherIngredients?.length
      ? editData?.otherIngredients
      : [{ name: "", quantity: "", unit: "gram" }],
    scheduleDate: scheduleDate ? dayjs(scheduleDate).format("YYYY-MM-DD") : ""
  });
  console.log("formData", formData);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const dispatch = useDispatch();
  const disabledDates = useSelector(selectScheduledDates);
  // Handle input change for title, generalText, and other inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle dynamic field changes for fruits, proteins, and others
  const handleDynamicInputChange = (index, e, type) => {
    const { name, value } = e.target;
    const updatedArray = formData[type].map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    setFormData((prev) => ({
      ...prev,
      [type]: updatedArray
    }));
  };

  // Handle adding new input for fruitsVeggies, proteins, or others
  const handleAddMore = (type) => {
    setFormData((prev) => ({
      ...prev,
      [type]: [
        ...prev[type],
        { name: "", quantity: "", unit: type === "others" ? "" : "gram" }
      ]
    }));
  };

  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 30 * 1024 * 1024) {
      setSnackbarMessage("File size should be less than 30MB.");
      setOpenSnackbar(true);
      return;
    }
    setFormData((prev) => ({
      ...prev,
      profilePic: file
    }));
  };


  const handleSubmit = async () => {
    try {
      setLoading(true);
      const mainData = new FormData();
      if (
        !formData.title ||
        (!editData && !formData.profilePic) ||
        !formData.scheduleDate
      ) {
        setSnackbarMessage(
          `${
            !formData.title
              ? "Title"
              : !editData && !formData.profilePic
              ? "Image"
              : !formData.scheduleDate
              ? "Schedule date"
              : ""
          } of meal is required`
        );
        setOpenSnackbar(true);
        setLoading(false);
        return;
      }
      if (
        formData.fruitsVeggies.length &&
        (!formData.fruitsVeggies[0].name || !formData.fruitsVeggies[0].quantity)
      ) {
        setSnackbarMessage("Provide atleast one fruit and veggies details.");
        setOpenSnackbar(true);
        setLoading(false);
        return;
      }
      // Prepare data object
      const data = {
        title: formData.title,
        fruitsVeggies: [],
        otherIngredients: [],
        protein: [],
        generalText: formData.generalText || "",
        scheduledDate: formData.scheduleDate,
        status: "approved"
      };

      // Process fruitsVeggies array
      if (
        formData.fruitsVeggies.length > 0 &&
        formData.fruitsVeggies[0].name !== ""
      ) {
        formData.fruitsVeggies.forEach((item) => {
          if (item.name && item.quantity) {
            data.fruitsVeggies.push({
              name: item.name,
              quantity: item.quantity,
              unit: item.unit
            });
          }
        });
      }

      // Process other ingredients array
      if (formData.others.length > 0 && formData.others[0].name !== "") {
        formData.others.forEach((item) => {
          if (item.name && item.quantity) {
            data.otherIngredients.push({
              name: item.name,
              quantity: item.quantity,
              unit: item.unit
            });
          }
        });
      }

      // Process proteins array
      if (formData.proteins.length > 0 && formData.proteins[0].name !== "") {
        formData.proteins.forEach((item) => {
          if (item.name && item.quantity) {
            data.protein.push({
              name: item.name,
              quantity: item.quantity,
              unit: item.unit
            });
          }
        });
      }

      // Convert data to JSON string
      const jsonString = JSON.stringify(data);

      // Encode JSON string to Base64 (btoa is used for encoding)
      const base64EncodedData = btoa(jsonString);

      // Create a new FormData object to send the base64 encoded data
      mainData.append("data", base64EncodedData);

      if (formData.profilePic) {
        mainData.append("mealImage", formData.profilePic);
      }
      const { payload } = await dispatch(
        editData
          ? updateScheduleDate({
              mealData: mainData,
              mealId: editData._id
            })
          : addNewMod(mainData)
      );
      setLoading(false);
      if (!payload.error) {
        setIsAddingMeal(false);
      } else {
        setSnackbarMessage(
          payload.error || "Failed to add meal. Please try again."
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      setLoading(false);
      setSnackbarMessage("Failed to add meal. Please try again.");
      setOpenSnackbar(true);
    }
  };
  const handleDelete = (index, type) => {
    const updatedArray = formData[type].filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      [type]: updatedArray
    }));
  };

  return (
    <Box component="form" margin="24px 40px 200px 0" display="flex">
      <Box>
        {formData.profilePic ? (
          <Avatar
            sx={{
              width: 360,
              height: 360,
              bgcolor: "#f0f0f0",
              color: "#bdbdbd",
              border: "1px dashed #D1D5DB",
              borderRadius: "4px",
              mb: 2
            }}
            src={URL.createObjectURL(formData.profilePic)}
          />
        ) : (
          <Box
            onClick={() => document.getElementById("upload-input").click()}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: 360,
              height: 360,
              border: "1px dashed #D1D5DB",
              borderRadius: "4px",
              position: "relative",
              cursor: "pointer",
              bgcolor: "#F9FAFB"
            }}
          >
            <CameraIcon />
            <Typography fontWeight="bold" color="#4B5563" mt={1}>
              Tap to upload
            </Typography>
            <Typography fontSize="12px" color="#6B7280">
              (Supported file PNG, JPEG)
            </Typography>
            <input
              id="upload-input"
              type="file"
              accept=".png, .jpg, .jpeg"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </Box>
        )}
      </Box>
      <Box width={"100%"}>
        <StyledTextFieldContainer>
          <StyledInputLabel htmlFor="title">Title</StyledInputLabel>
          <StyledTextField
            fullWidth
            variant="outlined"
            name="title"
            placeholder="Enter the title of the meal"
            value={formData.title}
            onChange={handleInputChange}
            required
            margin="normal"
          />
        </StyledTextFieldContainer>
        <StyledTextFieldContainer
          style={{
            marginBottom: 0,
            marginTop: "30px"
          }}
        >
          <StyledInputLabel htmlFor="fruitsvegies">
            Fruits & Veggies used in your meal
          </StyledInputLabel>
          {formData.fruitsVeggies.map((item, index) => (
            <Box display="flex" alignItems="center" gap="20px">
              <StyledTextField
                fullWidth
                variant="outlined"
                name="name"
                placeholder="Enter fruit or veggie"
                value={item.name}
                margin="normal"
                onChange={(e) =>
                  handleDynamicInputChange(index, e, "fruitsVeggies")
                }
                required
              />
              <StyledTextField
                type="number"
                name="quantity"
                margin="normal"
                placeholder="No."
                value={item.quantity}
                onChange={(e) =>
                  handleDynamicInputChange(index, e, "fruitsVeggies")
                }
                sx={{ marginTop: "16px", width: "15%" }}
              />
              <StyledTextField
                margin="normal"
                placeholder="Unit"
                value="Gram"
                disabled
                sx={{ marginTop: "16px", width: "15%" }}
              />
              {formData.fruitsVeggies?.length > 1 && (
                <IconButton
                  onClick={() => handleDelete(index, "fruitsVeggies")}
                  sx={{
                    fontSize: "16px",
                    borderRadius: "4px",
                    background: "#E94545",
                    svg: {
                      path: {
                        fill: "#FCFCFC"
                      }
                    },
                    "&:hover": {
                      background: "#FF4000"
                    }
                  }}
                >
                  <Delete height={24} width={24} />
                </IconButton>
              )}
            </Box>
          ))}
          <Button
            onClick={() => handleAddMore("fruitsVeggies")}
            sx={{
              textTransform: "none",
              color: "#38B65B",
              gap: "8px",
              borderRadius: "4px"
            }}
          >
            <Add
              sx={{
                path: {
                  fill: "#38B65B"
                }
              }}
            />
            Add more
          </Button>
        </StyledTextFieldContainer>
        <StyledTextFieldContainer
          style={{
            marginBottom: 0,
            marginTop: "30px"
          }}
        >
          <StyledInputLabel htmlFor="protein">Protein</StyledInputLabel>
          {formData.proteins.map((item, index) => (
            <Box display="flex" alignItems="center" gap="20px">
              <StyledTextField
                fullWidth
                variant="outlined"
                name="name"
                placeholder="Enter protein"
                value={item.name}
                margin="normal"
                onChange={(e) => handleDynamicInputChange(index, e, "proteins")}
                required
              />
              <StyledTextField
                type="number"
                name="quantity"
                margin="normal"
                placeholder="No."
                value={item.quantity}
                onChange={(e) => handleDynamicInputChange(index, e, "proteins")}
                sx={{ marginTop: "16px", width: "15%" }}
              />
              <StyledTextField
                margin="normal"
                placeholder="Unit"
                value="Gram"
                disabled
                sx={{ marginTop: "16px", width: "15%" }}
              />
            </Box>
          ))}
          <Button
            onClick={() => handleAddMore("proteins")}
            sx={{
              textTransform: "none",
              color: "#38B65B",
              gap: "8px",
              borderRadius: "4px"
            }}
          >
            <Add
              sx={{
                path: {
                  fill: "#38B65B"
                }
              }}
            />
            Add more
          </Button>
        </StyledTextFieldContainer>
        <StyledTextFieldContainer
          style={{
            marginBottom: 0,
            marginTop: "30px"
          }}
        >
          <StyledInputLabel htmlFor="other">Other ingredients</StyledInputLabel>
          {formData.others.map((item, index) => (
            <Box display="flex" alignItems="center" gap="20px">
              <StyledTextField
                fullWidth
                variant="outlined"
                name="name"
                placeholder="Enter other ingredients"
                value={item.name}
                margin="normal"
                onChange={(e) => handleDynamicInputChange(index, e, "others")}
                required
              />
              <StyledTextField
                type="number"
                name="quantity"
                margin="normal"
                placeholder="No."
                value={item.quantity}
                onChange={(e) => handleDynamicInputChange(index, e, "others")}
                sx={{ marginTop: "16px", width: "15%" }}
              />
              <FormControl sx={{ width: "20%", marginTop: "8px" }}>
                <InputLabel id="unit-label">Unit</InputLabel>
                <Select
                  labelId="unit-label"
                  name="unit"
                  value={item.unit}
                  label="Unit"
                  onChange={(e) => handleDynamicInputChange(index, e, "others")}
                >
                  <MenuItem value="gram">Gram</MenuItem>
                  <MenuItem value="pounds">Pounds</MenuItem>
                  <MenuItem value="cup">Cup</MenuItem>
                  <MenuItem value="pint">Pint</MenuItem>
                  <MenuItem value="Tps">Tps</MenuItem>
                  <MenuItem value="tps">tps</MenuItem>
                </Select>
              </FormControl>
              {formData.others.length > 1 && (
                <IconButton
                  onClick={() => handleDelete(index, "others")}
                  sx={{
                    fontSize: "16px",
                    borderRadius: "4px",
                    background: "#E94545",
                    svg: {
                      path: {
                        fill: "#FCFCFC"
                      }
                    },
                    "&:hover": {
                      background: "#FF4000"
                    }
                  }}
                >
                  <Delete height={24} width={24} />
                </IconButton>
              )}
            </Box>
          ))}
          <Button
            onClick={() => handleAddMore("others")}
            sx={{
              textTransform: "none",
              color: "#38B65B",
              gap: "8px",
              borderRadius: "4px"
            }}
          >
            <Add
              sx={{
                path: {
                  fill: "#38B65B"
                }
              }}
            />
            Add more
          </Button>
        </StyledTextFieldContainer>
        <Box m={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                shouldDisableDate={(date) => {
                  if (scheduleDate) return false;
                  const formattedDate = dayjs(date).format("YYYY-MM-DD");
                  return disabledDates.includes(formattedDate);
                }}
                defaultValue={
                  scheduleDate ? dayjs(formData.scheduleDate) : undefined
                }
                disabled={scheduleDate}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    scheduleDate: e.format("YYYY-MM-DD")
                  })
                }
                disablePast
                label="Schedule date"
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>
        <StyledTextFieldContainer style={{ marginTop: "30px" }}>
          <StyledInputLabel htmlFor="generalText">
            General Text
          </StyledInputLabel>
          <StyledTextField
            fullWidth
            variant="outlined"
            name="generalText"
            placeholder="Enter general text"
            value={formData.generalText}
            onChange={handleInputChange}
            required
            multiline
            rows={5}
            margin="normal"
          />
        </StyledTextFieldContainer>{" "}
        <Box ml={2} gap={2} display="flex">
          <Button
            style={{
              textTransform: "none",
              color: "#6B7280",
              fontWeight: 700,
              padding: "12px 20.5px"
            }}
            onClick={() => {
              setIsAddingMeal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={
              !formData.title ||
              (!editData && !formData.profilePic) ||
              (formData.fruitsVeggies.length &&
                (!formData.fruitsVeggies[0].name ||
                  !formData.fruitsVeggies[0].quantity))
            }
            sx={{
              textTransform: "none",
              color: "#fcfcfc",
              padding: "12px 20.5px",
              background: "#38B65B",
              fontWeight: 700,
              "&:disabled": {
                backgroundColor: "#D7F0DE",
                color: "#9CDBAD",
                pointerEvents: "none"
              }
            }}
          >
            {loading ? <CircularProgress size={24} /> : "Submit"}
          </Button>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={() => setOpenSnackbar(false)}
          >
            <Alert onClose={() => setOpenSnackbar(false)} severity="error">
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Box>
  );
};
export default AddMod;
