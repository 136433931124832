import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import AllUsers from "../Tables/UserTable"; // Updated import
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllUsers,
  selectCurrentPage,
  selectLimit,
  selectTotalUsers,
  selectUsersError,
  selectUsersLoading
} from "../../features/users/userSelectors";
import { fetchUsersByType } from "../../features/users/userSlice";
import { ReactComponent as UsersIcon } from "../../assets/users.svg";

const OnlyUserTab = ({ isAdmin, openModal, searchValue }) => {
  const dispatch = useDispatch();

  const users = useSelector(selectAllUsers); // Now fetched from Redux
  const totalUsers = useSelector(selectTotalUsers);
  const page = useSelector(selectCurrentPage);
  const limit = useSelector(selectLimit);
  const loading = useSelector(selectUsersLoading);
  const error = useSelector(selectUsersError);

  useEffect(() => {
    if (!searchValue || searchValue?.length > 3) {
      dispatch(fetchUsersByType({ page, limit, searchValue }));
    }
  }, [dispatch, page, limit, openModal, searchValue]);

  return (
    <Box mt={4}>
      <Box
        height={"92px"}
        display="flex"
        gap="20px"
        marginTop={3}
        marginBottom={3}
      >
        <Box
          height={"100%"}
          width={"50%"}
          border="1px solid #D1D5DB"
          backgroundColor="#FCFCFC"
          borderRadius="6px"
        >
          <Box display="flex">
            <UsersIcon
              style={{ margin: "14px 16px 3px 16px" }}
              height={24}
              width={24}
            />
            <Typography
              margin="14px 16px 3px 16px"
              fontWeight={400}
              color="#6B7280"
              fontSize="15px"
            >
              Total available seats
            </Typography>
          </Box>
          <Typography margin={"0 16px"} fontSize="30px" fontWeight={600}>
            100
          </Typography>
        </Box>
        <Box
          height={"100%"}
          width={"50%"}
          border="1px solid #D1D5DB"
          backgroundColor="#FCFCFC"
          borderRadius="6px"
        >
          <Box display="flex">
            <UsersIcon
              style={{ margin: "14px 16px 3px 16px" }}
              height={24}
              width={24}
            />
            <Typography
              margin="14px 16px 3px 16px"
              fontWeight={400}
              color="#6B7280"
              fontSize="15px"
            >
              Occupied seats
            </Typography>
          </Box>
          <Typography margin={"0 16px"} fontSize="30px" fontWeight={600}>
            {totalUsers ? totalUsers : "-"}
          </Typography>
        </Box>
      </Box>
      {users?.length ? (
        <AllUsers
          totalUsers={totalUsers}
          users={users}
          loading={loading}
          isAdmin={false}
          error={error}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default OnlyUserTab;
