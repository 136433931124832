import { Avatar, Box, IconButton, Modal, Typography } from "@mui/material";
import { calculateTotalQuantity } from "../../utils/getTotal";
import { ReactComponent as AppleIcon } from "../../assets/apple.svg";
import { ReactComponent as ProteinIcon } from "../../assets/protein.svg";
import { Close } from "@mui/icons-material";
import getInitials from "../../utils/getInitials";

const PreviewModal = ({ onClose, currentMeal }) => {
  const totalFruitsVeggies = calculateTotalQuantity(
    currentMeal?.fruitsVeggies || []
  );
  const totalOtherIngredients = calculateTotalQuantity(
    currentMeal?.otherIngredients || []
  );
  const totalProtein = calculateTotalQuantity(currentMeal?.protein || []);
  return (
    <Modal
      open={currentMeal?._id}
      onClose={onClose}
      aria-labelledby="meal-modal-title"
      aria-describedby="meal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 1,
          borderRadius: 2
        }}
      >
        <Box display="flex" justifyContent="end">
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Box gap={2} p="10px 20px" display="flex">
          <Box width={"100%"}>
            <Box
              component="img"
              alt="meal"
              src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_MEAL_IMAGE_LOCATION}/${currentMeal?.mealImage}`}
              height={200}
              width={"100%"}
              borderRadius={10}
            />
          </Box>

          <Box width="100%">
            <Typography fontSize={"28px"} fontWeight={600}>
              {currentMeal?.title}
            </Typography>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box>
                <Box display="flex" gap="10px" alignItems="center">
                  {currentMeal?.userData?.[0]?.profilePic ? (
                    <Box
                      component="img"
                      height={40}
                      borderRadius="50%"
                      border="1px solid #38B65B"
                      width={40}
                      src={`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION}/${currentMeal?.userData?.[0]?.profilePic}`}
                      alt="avatar"
                    />
                  ) : (
                    <Avatar sx={{ width: 40, height: 40 }}>
                      {getInitials(
                        currentMeal?.adminUserData?.[0]?.name ||
                          currentMeal?.userData?.[0]?.fullName
                      )}
                    </Avatar>
                  )}{" "}
                  <Typography fontSize="14px" fontWeight={400}>
                    {currentMeal?.adminUserData?.[0]?.name ||
                      currentMeal?.userData?.[0]?.fullName}
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box mt={3} height={64} borderRadius={2} display="flex" gap="10px">
              <Box
                width={"100%"}
                alignItems={"center"}
                justifyContent={"left"}
                display="flex"
                gap={2}
                sx={{
                  background: "#333333",
                  padding: "8px 16px",
                  color: "#9CA3AF"
                }}
                borderRadius="8px"
              >
                <AppleIcon />
                <Box>
                  <Typography fontSize="12px" fontWeight={400} color="#8E8E8E">
                    800g
                  </Typography>
                  <Typography fontSize="24px" fontWeight={500} color="#F6541C">
                    {totalFruitsVeggies + totalOtherIngredients}
                  </Typography>
                </Box>
              </Box>

              <Box
                width={"100%"}
                alignItems={"center"}
                justifyContent={"left"}
                display="flex"
                gap={2}
                sx={{
                  background: "#333333",
                  padding: "8px 16px",
                  color: "#9CA3AF"
                }}
                borderRadius="8px"
              >
                <ProteinIcon />
                <Box>
                  <Typography fontSize="12px" fontWeight={400} color="#8E8E8E">
                    Protein
                  </Typography>
                  <Typography fontSize="24px" fontWeight={500} color="#2C75C5">
                    {totalProtein}g
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box
              width={"100%"}
              sx={{
                background: "#333333",
                padding: "12px 16px",
                color: "#9CA3AF",
                mt: 3,
                borderRadius: "8px"
              }}
            >
              <Typography color="#FCFCFC" fontSize="16px" fontWeight={600}>
                Ingredients
              </Typography>
              <ul
                style={{
                  paddingLeft: "18px",
                  margin: 0,
                  marginTop: 8,
                  color: "#D1D5DB"
                }}
              >
                {currentMeal?.fruitsVeggies?.map((item) => (
                  <li key={item._id}>
                    <Typography variant="body1">
                      {item.quantity} {item.unit} {item.name}
                    </Typography>
                  </li>
                ))}
                {currentMeal?.otherIngredients?.map((item) => (
                  <li key={item._id}>
                    <Typography variant="body1">
                      {item.quantity} {item.unit} {item.name}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>

            <Box
              width={"100%"}
              sx={{
                background: "#333333",
                padding: "12px 16px",
                mt: 3,
                borderRadius: "8px"
              }}
            >
              <Typography color="#FCFCFC" fontSize="16px" fontWeight={600}>
                Details
              </Typography>
              <Typography mt={1} variant="body1" color="#D1D5DB">
                {currentMeal?.generalText}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default PreviewModal;
