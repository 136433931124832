import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { ReactComponent as TPillars } from "../../assets/tpillars.svg";
import { ReactComponent as EmptyTPillars } from "../../assets/empty.svg";
import { ReactComponent as HomeIcon } from "../../assets/home.svg";
import { ReactComponent as ModIcon } from "../../assets/mod.svg";
import { ReactComponent as TrainingIcon } from "../../assets/training.svg";
import { ReactComponent as PaymentIcon } from "../../assets/dollar.svg";
import { ReactComponent as UserIcon } from "../../assets/user.svg";
import { ReactComponent as BellIcon } from "../../assets/bell.svg";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { Avatar, Menu, MenuItem } from "@mui/material";
import Users from "../Tabs/Users";
import Mod from "../Tabs/MOD";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import getInitials from "../../utils/getInitials";
import { ArrowBack } from "@mui/icons-material";

const drawerWidth = 265;
const StyledPaymentIcon = styled(PaymentIcon)({
  width: 22,
  height: 17,
  "& path": {
    fill: "#9CA3AF" // Ensure you target the path element correctly
  }
});
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 10px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 24px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  height: "64px",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(4, 1),
  ...theme.mixins.toolbar
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }),
  ...(!open && {
    marginLeft: `calc(${theme.spacing(7)} + 10px)`,
    width: `calc(100% - calc(${theme.spacing(7)} + 33px))`
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  width: drawerWidth - 20,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflow: "visible",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": {
      ...openedMixin(theme),
      overflow: "visible"
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": {
      ...closedMixin(theme),
      overflow: "visible"
    }
  })
}));

const AdminDashboard = () => {
  const currentAdmin = useSelector((state) => state.adminauth.admin);
  const [open, setOpen] = React.useState(true);
  const [activeTab, setActiveTab] = React.useState("Users");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isAddingMeal, setIsAddingMeal] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await dispatch(logoutUser());
    navigate("/");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Home":
        return <Typography>Home content goes here</Typography>;
      case "MOD":
        return (
          <Mod isAddingMeal={isAddingMeal} setIsAddingMeal={setIsAddingMeal} />
        );
      case "Users":
        return <Users />;
      case "Training":
        return <Typography>Training content goes here</Typography>;
      default:
        return <Typography>Default content</Typography>;
    }
  };

  const adminRenderContent = () => {
    switch (activeTab) {
      case "Home":
        return <Typography>Home content goes here</Typography>;
      case "Users":
        return <Users isAdmin={false}/>;
      case "Billing":
        return <Typography>Payment content goes here</Typography>;
      default:
        return <Typography>Default content</Typography>;
    }
  };

  const menuItems = !currentAdmin.adminUserId
    ? [
        { text: "Home", icon: <HomeIcon /> },
        { text: "Users", icon: <UserIcon /> },
        {
          text: "Billing",
          icon: <StyledPaymentIcon />
        }
      ]
    : [
        { text: "Home", icon: <HomeIcon /> },
        { text: "MOD", icon: <ModIcon /> },
        { text: "Users", icon: <UserIcon /> },
        { text: "Training", icon: <TrainingIcon /> }
      ];
  return (
    <Box display="flex">
      <CssBaseline />
      <AppBar open={open}>
        <Toolbar
          sx={{
            background: "#F9FAFB",
            justifyContent: "space-between",
            height: "95px"
          }}
        >
          <Box display="flex" alignItems="center" gap="10px">
            {isAddingMeal && (
              <ArrowBack
                onClick={(e) => {
                  setIsAddingMeal(false);
                }}
                sx={{ color: "#374151", cursor: "pointer" }}
              />
            )}{" "}
            <Typography
              variant="h5"
              fontWeight="bold"
              color="black"
              noWrap
              component="div"
            >
              {activeTab === "MOD" ? "Meal Of The Day" : activeTab}
            </Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <IconButton>
              <BellIcon />
            </IconButton>
            <IconButton>
              <SearchIcon />
            </IconButton>
            <IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button"
                }}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
              {currentAdmin?.profilePic ? (
                <Box
                  onClick={handleClick}
                  component="img"
                  borderRadius="50%"
                  border="1px solid #38B65B"
                  height={45}
                  width={45}
                  src={`${
                    process.env.REACT_APP_BACKEND_URL +
                    process.env.REACT_APP_BACKEND_PROFILE_IMAGE_LOCATION
                  }/${currentAdmin?.profilePic}`}
                  alt="avatar"
                />
              ) : (
                <Avatar onClick={handleClick} height={45} width={45}>
                  {getInitials(currentAdmin?.fullName)}
                </Avatar>
              )}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          ".MuiDrawer-paper": {
            background: "#14142B",
            color: "#9CA3AF"
          }
        }}
      >
        <DrawerHeader>
          {open ? (
            <TPillars />
          ) : (
            <EmptyTPillars style={{ margin: "25px 0 0 5px" }} />
          )}
          <IconButton
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            sx={{
              marginLeft: "20px",
              position: "absolute",
              right: -10,
              top: "5%",
              transform: "translateY(-50%)",
              color: "#38B65B",
              zIndex: 1300
            }}
          >
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List style={{ marginTop: "20px" }}>
          {menuItems.map(({ text, icon }) => (
            <ListItem key={text} sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => handleTabChange(text)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    ...(text === activeTab &&
                      text !== "Training" && {
                        svg: {
                          path: {
                            stroke: "#38B65B"
                          }
                        }
                      })
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={text}
                  sx={{
                    opacity: open ? 1 : 0,
                    ...(text === activeTab && {
                      color: "#38B65B"
                    })
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <DrawerHeader
          style={{
            height: "95px"
          }}
        />
        <Box sx={{ background: "#f3f4f6" }} p={4}>
          {!currentAdmin.adminUserId ? adminRenderContent() : renderContent()}
        </Box>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
